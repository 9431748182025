import {
  showErrorMessage,
  showSuccessMessage,
} from "./notification";
import { MESSAGE_TYPE } from "store/constant";
import axiosService, { getAuthToken } from "services/axios.service";
import { allRolesArray } from "utils/adminRolesOptions";
import { handleThrowError, handleThrowSuccess } from "utils/alerts";
import axios from "axios";

// initial values
const authData = {
  loading: false,
  currentUser: {},
  loggedinUserDetails: {},
};

// Action types
const REGISTERED = "app/auth/REGISTERED";
const LOGGED_OUT = "app/auth/LOGGED_OUT";
const LOADING = "app/auth/LOADING";
const LOGGED_IN_USER = "app/auth/LOGGED_IN_USER";

// Reducer
export default function reducer(state = authData, action = {}) {
  switch (action.type) {
    case LOGGED_OUT:
      return {
        ...state,
        error: null,
        fetching: false,
        currentUser: null,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LOGGED_IN_USER:
      return {
        ...state,
        error: null,
        fetching: false,
        loggedInUserDetails: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function userRegistered() {
  return { type: REGISTERED };
}

export function isRequestLoading(payload, type) {
  return {
    type: type || LOADING,
    payload,
  };
}

export function userLoggedOut() {
  return { type: LOGGED_OUT };
}

export function userDetails(user) {
  return { type: LOGGED_IN_USER, payload: user };
}

export function loginUser({ username, password, role, }, navigate, redirectURL) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.Auth.login(username, password, role).then(
      (response) => {
        const { access_type } = response.data.data;
        // Save user auth data (set token in local storage)
        axiosService.Auth.saveAuthData(response);
        // Set the token for the axios request
        const token = getAuthToken();
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        // Query Params
        const roleQuery = `?role=${role}`
        const accessTypeQuery = access_type ? `&access_type=${access_type}` : ""
        const redirectUrlQuery = redirectURL ? `&redirect=${redirectURL}` : ""

        const { isCompany2FAEnabled, isUser2FAEnabled } = response.data;
        // If company 2fa is enabled, store in the local storage that the company 2fa is enabled for this user 
        if (isCompany2FAEnabled) {
          localStorage.setItem("isCompany2FAEnabled", true)
        }
        // Check if company 2fa is enabled and user has not set up 2fa, redirect user to 2fa setup page else if user has set up 2fa, redirect user to the verify 2fa page else if 2fa is not enabled, redirect user to the dashboard
        if (isCompany2FAEnabled && !isUser2FAEnabled) {
          // Redirect user to 2fa setup page
          const url = `/auth/setup-2fa`
          // Add redirect url, role, and access type to the url query params if they exist
          navigate(`${url}${roleQuery}${accessTypeQuery}${redirectUrlQuery}`)
          // Set loading to false and 
          dispatch(isRequestLoading(false));
          return;
        } else if (isCompany2FAEnabled && isUser2FAEnabled) {
          // Redirect user to 2fa verify page
          const url = `/auth/verify-2fa`
          // Add redirect url, role, and access type to the url query params if they exist
          navigate(`${url}${roleQuery}${accessTypeQuery}${redirectUrlQuery}`)
          // Set loading to false and 
          dispatch(isRequestLoading(false));
          return;
        }

        // If 2fa is not enabled by the company, check if user has a redirect url, if yes, redirect user to the url, else redirect user to the appropriate dashboard
        handleUserLoginNavigationAndRedirection(dispatch, navigate, access_type, role, redirectURL)

      },
      (error) => {
        dispatch(isRequestLoading(false));
        // Handle Throw Error
        handleThrowError(dispatch, error.response.data.message || "An error occured")
      }
    );
  };
}

// Handle Get the user role and redirect to the appropriate dashboard or redirect url
const handleUserLoginNavigationAndRedirection = function (dispatch, navigate, access_type, role, redirectURL) {

  // IRRESPECTIVE OF ROLE, IF USER IS HAS A REDIRECT URL, REDIRECT USER TO THE URL  
  if (redirectURL) {
    dispatch(loggedInUser(navigate));
    dispatch(isRequestLoading(false));
    navigate(redirectURL)
    return;
  }

  // FOR ADMINS
  if (role === "admin") {
    if (access_type === "DATAMAX ADMIN") {
      dispatch(loggedInUser(navigate));
      dispatch(isRequestLoading(false));
      // navigate("/admin/license-management");
      window.location.href = "/admin/license-management"
      return;
    }

    if (access_type !== "General staff" && allRolesArray.includes(access_type)) {
      dispatch(loggedInUser(navigate));
      dispatch(isRequestLoading(false));
      if (access_type === "File Control Admin" || access_type === "Auditor" || access_type === "COMPANY ADMIN") {
        // navigate("/admin/category");
        window.location.href = "/admin/category"
      } else {
        // navigate("/admin/dashboard");
        window.location.href = "/admin/dashboard"
      }
      return;
    }

  }

  if (role === "user" && access_type !== "DATAMAX ADMIN") {
    dispatch(loggedInUser(navigate));
    navigate("/dashboard");
    dispatch(isRequestLoading(false));
    return;

  }

  // Handle Throw Error
  handleThrowError(dispatch, "Invalid role, Try again with the correct role")
  dispatch(isRequestLoading(false));
  return;
}



export function loggedInUser(navigate) {
  return (dispatch) => {
    axiosService.User.signedUserDetail()
      .then((response) => {
        dispatch(userDetails(response));
      }, (error) => {
        // Handling Errors
        const errorObject = error?.response
        // Check if response status is not between 200 and 299
        if (errorObject?.data?.message === "You are not authenticated, Please login") {
          // If error is non-authentication, check if user is in the preview page, if yes, navigate to login noting the preview link and after authentication, navigate user to the preview link, if not just navigate user to the login page
          if (window.location.pathname.includes("/preview-file")) {
            navigate(`/login?preview=${window.location.pathname}`)
          } else {
            navigate("/login", { state: { toastMessage: errorObject?.data?.message } })
          }
        } else {
          // Throw error
          handleThrowError(dispatch, errorObject?.data?.message || "An error occured")
        }
        return;
      })
  };
}

export function OnLogout(navigate, message) {
  return (dispatch) => {
    axiosService.Auth.logoutUser().then(() => {
      axiosService.Auth.logout()
      dispatch(userLoggedOut());
      // Take user to sign in page
      if (navigate && message) {
        navigate("/", { state: { toastMessage: message } });
      } else {
        window.location.href = "/"
      }

    }, () => {
      axiosService.Auth.logout()
      dispatch(userLoggedOut());
      // Take user to sign in page
      if (navigate && message) {
        navigate("/", { state: { toastMessage: message } });
      } else {
        window.location.href = "/"
      }
    })
  };
}

export function changePassword({ current_password, new_password }) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.User.updatePassword({
      current_password,
      new_password,
    }).then(
      (response) => {
        dispatch(isRequestLoading(false));
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "Password successfully changed",
            title: "Success",
          })
        );
        document.getElementById("closeChangePasswordModal").click();
        dispatch(OnLogout());
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error.response.data.message,
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function uploadSignature(signature, setIsModalOpened) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.User.uploadSignature(signature).then(
      (response) => {
        dispatch(isRequestLoading(false));
        // Close all opened modals
        document.getElementById("closeUploadSignatureModal").click();
        setIsModalOpened && setIsModalOpened(false)
        // Throw success and refetch user details to update signature
        handleThrowSuccess(dispatch, "Signature successfully uploaded")
        dispatch(loggedInUser());
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error.response.data.message,
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}



// 2 Factor Authentication
// Is 2FA Enabled
export function is2FAEnabled() {
  return (dispatch) => {
    dispatch(isRequestLoading(LOADING, true));
    return axiosService.User.is2FAEnabled().then(
      (response) => {
        dispatch(isRequestLoading(LOADING, false));
        return response;
      },
      (error) => {
        dispatch(isRequestLoading(LOADING, false));
        // Handle Throw error
        handleThrowError(dispatch, error || "Failed to check 2FA status!")
      }
    );
  };
}

// Enable 2FA
export function enable2FA(setQRCodeBase64) {
  return (dispatch) => {
    dispatch(isRequestLoading(LOADING, true));
    return axiosService.Auth.enable2FA().then(
      (response) => {
        dispatch(isRequestLoading(LOADING, false));
        const qrCode = response.data.data;
        setQRCodeBase64(qrCode)
        // Throw Success Message 
        // handleThrowSuccess(dispatch, "2FA enabled successfully!")
      },
      (error) => {
        dispatch(isRequestLoading(LOADING, false));
        // Handle Throw error
        handleThrowError(dispatch, error || "Failed to enable 2FA!")
      }
    );
  };
}

// Verify 2FA
export function verify2FA(data, navigate, setIsLoading, queryParameters) {
  const { access_type, role, redirectURL } = queryParameters
  return (dispatch) => {
    setIsLoading(true);
    return axiosService.Auth.verify2FA(data).then(
      (response) => {
        setIsLoading(false)
        // Throw Success Message
        handleThrowSuccess(dispatch, "2FA verified successfully!");
        // Store in the local storage that the user has verified 2fa
        localStorage.setItem("isUser2FAVerified", true)
        // Handle User Login Navigation and Redirection
        setTimeout(() => {
          handleUserLoginNavigationAndRedirection(dispatch, navigate, access_type, role, redirectURL)
        }, 2000)
      },
      (error) => {
        setIsLoading(false)
        // Handle Throw error
        handleThrowError(dispatch, error || "Failed to verify 2FA!")
      }
    );
  };
}

// Disable 2FA
// export function disable2FA() {
//   return (dispatch) => {
//     dispatch(isRequestLoading(LOADING, true));
//     return axiosService.User.disable2FA().then(
//       (response) => {
//         dispatch(isRequestLoading(LOADING, false));
//         // Throw Success Message
//         handleThrowSuccess(dispatch, "2FA disabled successfully!")
//       },
//       (error) => {
//         dispatch(isRequestLoading(LOADING, false));
//         // Handle Throw error
//         handleThrowError(dispatch, error || "Failed to disable 2FA!")
//       }
//     );
//   };
// }

// Clear 2FA Session
export function clear2FASession(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(LOADING, true));
    return axiosService.Auth.clear2FASession(data).then(
      (response) => {
        dispatch(isRequestLoading(LOADING, false));
        // Throw Success Message
        handleThrowSuccess(dispatch, "2FA session cleared successfully!")
      },
      (error) => {
        dispatch(isRequestLoading(LOADING, false));
        // Handle Throw error
        handleThrowError(dispatch, error || "Failed to clear 2FA session!")
      }
    );
  };
}