import { showErrorMessage, showSuccessMessage, showWarningMessage } from "./notification";
import axiosService, { API_ROOT } from "services/axios.service";
import { MESSAGE_TYPE } from "store/constant";
import { loadCheckedOutFiles, loadFavouriteFiles, loadFile, loadFileVersionHistory, onFileDetailLoad } from "./file";
import { handleThrowError, handleThrowSuccess } from "utils/alerts";
import { loadAppNotification } from "./appNotification";
import { loadDashboardData } from "./dashboard";
import { binaryToFileConverter } from "utils/binaryToFileConverter";
import { binaryToPDFConverter } from "utils/binaryToPDFConverter";
import { binaryToAnyFileConverter } from "utils/binaryToAnyFileConverter";

// initial values
const Initial_State = {
  loading: false,
  checkInLoading: false,
  error: false,
};

// Action types
const LOADING = "FILE_ACTION_LOADING";
const DOWNLOAD_MAIN_FILE_LOADING = "DOWNLOAD_MAIN_FILE_LOADING";
const DOWNLOAD_OTHER_FILE_LOADING = "DOWNLOAD_OTHER_FILE_LOADING";
const CHECK_IN_LOADING = "CHECK_IN_LOADING";
const ERROR = "ERROR";

// Reducer
export default function reducer(state = Initial_State, action = {}) {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
        error: null,
      };
    case DOWNLOAD_MAIN_FILE_LOADING:
      return {
        ...state,
        downloadMainFileLoading: action.payload,
        error: null,
      };
    case DOWNLOAD_OTHER_FILE_LOADING:
      return {
        ...state,
        downloadOtherFileLoading: action.payload,
        error: null,
      };
    case CHECK_IN_LOADING:
      return {
        ...state,
        checkInLoading: action.payload,
        error: null,
      };
    default:
      return state;
  }
}

export function isRequestLoading(data) {
  return {
    type: LOADING,
    payload: data,
  };
}

export function isDownloadFileLoading(constant, data) {
  return {
    type: constant,
    payload: data,
  };
}

export function isCheckInLoading(data) {
  return {
    type: CHECK_IN_LOADING,
    payload: data,
  };
}

export function isError(data) {
  return {
    type: ERROR,
    payload: data,
  };
}


export function postComment(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileAction.uploadFileComment(data).then(
      (response) => {
        //handle success
        dispatch(isRequestLoading(false));
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "Comment posted successfully",
            title: "Success",
          })
        );
        dispatch(loadFile(data.file_id));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to post comments",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function deleteOtherFile(data, file_id) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileAction.deleteOtherFiles(data).then(
      (response) => {
        //handle success
        dispatch(isRequestLoading(false));
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "File deleted successfully",
            title: "Success",
          })
        );
        dispatch(loadFile(file_id));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to delete file",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}


// Download File Publicly 
export const downloadFilePublicly = (dispatch, id) => {
  // console.log(`${API_ROOT}/api/download-files/${id}`)
  // window.open(`${API_ROOT}/api/download-files/${id}`, "_blank");

  // Handle Throw Success Message
  handleThrowSuccess(dispatch, "File downloaded successfully!")
}


// Download File Old 
export function downloadFiles(id, fileName, fileExtension = "pdf") {
  return (dispatch) => {
    dispatch(isDownloadFileLoading(DOWNLOAD_MAIN_FILE_LOADING, true));
    return axiosService.FileAction.downloadFile(id).then(
      (response) => {
        dispatch(isDownloadFileLoading(DOWNLOAD_MAIN_FILE_LOADING, false));
        // Convert Binary to File
        const binaryData = response.data;
        const fileNameWithoutExtension = fileName?.split('.').slice(0, -1).join('.');
        const fileTitle = `${fileNameWithoutExtension || fileName}.${fileExtension}`
        binaryToAnyFileConverter(binaryData, fileTitle);
        // Refetch file
        dispatch(loadFile(id));
      },
      (error) => {
        // Handle Throw Error
        handleThrowError(dispatch, error || "Failed to download file!")
        dispatch(isDownloadFileLoading(DOWNLOAD_MAIN_FILE_LOADING, false));
      }
    );
  };
}

export function downloadOtherFiles(id, originalFileId, fileName, fileExtension = "pdf") {
  return (dispatch) => {
    dispatch(isDownloadFileLoading(DOWNLOAD_OTHER_FILE_LOADING, true));
    return axiosService.FileAction.downloadOtherFile(id).then(
      (response) => {
        //handle success
        dispatch(isDownloadFileLoading(DOWNLOAD_OTHER_FILE_LOADING, false));
        // Convert Binary to File
        const binaryData = response.data;
        const fileNameWithoutExtension = fileName?.split('.').slice(0, -1).join('.')
        const fileTitle = `${fileNameWithoutExtension || fileName}.${fileExtension}`
        binaryToAnyFileConverter(binaryData, fileTitle);
        // Handle Throw Success Message
        handleThrowSuccess(dispatch, "File downloaded successfully!")
        //  Refetch file
        dispatch(loadFile(originalFileId));
      },
      (error) => {
        // Handle Throw Error
        handleThrowError(dispatch, error || "Failed to download file!")
        dispatch(isDownloadFileLoading(DOWNLOAD_OTHER_FILE_LOADING, false));
      }
    );
  };
}

export function deleteFile(data, handleOnDeleteFileSuccess) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileAction.deleteFile(data).then(
      (response) => {
        //handle success
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "File deleted successfully",
            title: "Success",
          })
        );
        dispatch(isRequestLoading(false));

        handleOnDeleteFileSuccess()
        // document.getElementById("file-modal-view").click();
        // dispatch(loadDashboardData());
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to delete file",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}



export function followFile(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileAction.followFile(data).then(
      (response) => {
        //handle success
        dispatch(isRequestLoading(false));
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "File followed successfully",
            title: "Success",
          })
        );
        dispatch(loadFile(data?.file_id));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to follow file",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function unFollowFile(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileAction.unfollowFile(data).then(
      (response) => {
        //handle success
        dispatch(isRequestLoading(false));
        dispatch(
          showWarningMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "File unfollowed successfully",
            title: "Success",
          })
        );
        dispatch(loadFile(data?.file_id));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to unfollow file",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function favouriteFile(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileAction.favouriteFile(data).then(
      (response) => {
        //handle success
        dispatch(isRequestLoading(false));
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "File added to favourites successfully",
            title: "Success",
          })
        );
        dispatch(loadFile(data?.file_id));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to add file to favourites",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function unFavouriteFile(data, parentPageType) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileAction.unFavouriteFile(data).then(
      (response) => {
        //handle success
        dispatch(isRequestLoading(false));
        dispatch(
          showWarningMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "File removed from favourites successfully",
            title: "Success",
          })
        );

        parentPageType === "favourite" && dispatch(loadFavouriteFiles(20, 1));
        parentPageType === "favourite" && dispatch(onFileDetailLoad({}));
        parentPageType !== "favourite" && dispatch(loadFile(data?.file_id));


      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to unfavourite this file",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function rollBackFile(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileAction.rollBackFile(data).then(
      (response) => {
        //handle success
        dispatch(isRequestLoading(false));
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "File rolled back successfully",
            title: "Success",
          })
        );
        dispatch(loadFileVersionHistory(data.file_id));
        dispatch(loadFile(data.file_id));

      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to roll back this file",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}




// Lock || Unlock File
export function lockUnlockFile(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileAction.lockUnlockFile(data).then(
      (response) => {
        //handle success
        dispatch(isRequestLoading(false));
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: `File ${data.status === "1" ? "locked" : "unlocked"} successfully`,
            title: "Success",
          })
        );
        dispatch(loadFile(data?.file_id));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: `Failed to  ${data.status === 1 ? "locked" : "unlocked"} file`,
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

// Check In File
export function checkInNewFile(data, fileId, setIsModalOpened, parentPageType) {
  return (dispatch) => {
    dispatch(isCheckInLoading(true));
    return axiosService.FileAction.checkInFile(data).then(
      (response) => {
        //handle success
        dispatch(isCheckInLoading(false));
        setIsModalOpened(false)
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "File checked in successfully",
            title: "Success",
          })
        );
        dispatch(loadFile(fileId));

        parentPageType === "checked-out" && dispatch(loadCheckedOutFiles(20, 1));
        parentPageType === "checked-out" && dispatch(onFileDetailLoad({}));
        parentPageType !== "checked-out" && dispatch(loadFile(fileId));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to check in file!",
          })
        );
        dispatch(isCheckInLoading(false));
      }
    );
  };
}

// Check Out File
export function checkOutFile(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileAction.checkOutFile(data).then(
      (response) => {
        //handle success
        dispatch(isRequestLoading(false));
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "File checked out successfully",
            title: "Success",
          })
        );
        dispatch(loadFile(data?.file_id));


      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to check out file!",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}



// Export File
export function exportFile(fileId, handleOnExportFileSuccess) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileAction.exportFile(fileId).then(
      (response) => {
        // handle success
        dispatch(isRequestLoading(false));
        handleOnExportFileSuccess(response?.data);
        // Show Success Message
        handleThrowSuccess(dispatch, "File exported successfully!")
        // Refetch file
        dispatch(loadFile(fileId));
      },
      (error) => {
        // Throw Error
        handleThrowError(dispatch, error || "Failed to export file!")
        dispatch(isRequestLoading(false));
      }
    );
  };
}

// Manually Trigger Workflow Step
export function manuallyTriggerWorkflowStep(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileAction.manuallyTriggerWorkflowStep(data).then(
      (response) => {
        dispatch(isRequestLoading(false));
        //handle success
        handleThrowSuccess(dispatch, "File workflow stage updated successfully!")
        dispatch(loadFile(data?.file_id));
        // Refetches the dashboard table data and app notifications
        dispatch(loadAppNotification());
        dispatch(loadDashboardData());

      },
      (error) => {
        handleThrowError(dispatch, error || "Failed to manually update workflow stage!")
        dispatch(loadFile(data?.file_id));
        dispatch(isRequestLoading(false));
      }
    );
  };
}

