import axios from "axios";
import { showErrorMessage } from "./notification";
import axiosService, { API_ROOT, getAuthToken } from "services/axios.service";
import { handleThrowError } from "utils/alerts";

// initial values
const Initial_State = {
  searchResult: {},
  loading: false,
  error: false,
};

// Action types
const LOAD_SEARCH_RESULT_FOR_HEADER = "LOAD_SEARCH_RESULT_FOR_HEADER";
const LOAD_SEARCH_RESULT_FOR_RESULTS = "LOAD_SEARCH_RESULT_FOR_RESULTS";
const HEADER_LOADING = "HEADER_LOADING";
const RESULTS_LOADING = "RESULTS_LOADING";
const ERROR = "ERROR";

// Reducer
export default function reducer(state = Initial_State, action = {}) {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case LOAD_SEARCH_RESULT_FOR_HEADER:
      return {
        ...state,
        error: null,
        loading: false,
        searchResultForHeader: action?.payload,
      };
    case LOAD_SEARCH_RESULT_FOR_RESULTS:
      return {
        ...state,
        error: null,
        loading: false,
        searchResultForResults: action?.payload,
      };
    case HEADER_LOADING:
      return {
        ...state,
        headerLoading: action.payload,
        error: null,
      };
    case RESULTS_LOADING:
      return {
        ...state,
        resultsLoading: action.payload,
        error: null,
      };
    default:
      return state;
  }
}

export function onLoadResultForHeader(data) {
  return {
    type: LOAD_SEARCH_RESULT_FOR_HEADER,
    payload: data,
  };
}

export function onLoadResultForResults(data) {
  return {
    type: LOAD_SEARCH_RESULT_FOR_RESULTS,
    payload: data,
  };
}

export function isRequestLoadingForHeader(data) {
  return {
    type: HEADER_LOADING,
    payload: data,
  };
}
export function isRequestLoadingForResults(data) {
  return {
    type: RESULTS_LOADING,
    payload: data,
  };
}

export function isError(data) {
  return {
    type: ERROR,
    payload: data,
  };
}


const token = getAuthToken()
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

// Initialize the abort controller
let abortController = null;


export function loadSearchResultsForHeader(searchvalue, quantity, page) {
  return (dispatch) => {
    dispatch(isRequestLoadingForHeader(true));

    // Cancel the previous request
    if (abortController) {
      abortController.abort();
    }
    abortController = new AbortController();
    // Construct the URL and query string
    const endpointURL = new URL(`${API_ROOT}/api/search-files`);
    if (searchvalue)
      endpointURL.searchParams.append("search", searchvalue);
    if (quantity)
      endpointURL.searchParams.append("quantity", quantity);
    if (page)
      endpointURL.searchParams.append("page", page);

    axios
      .get(endpointURL.href, { signal: abortController.signal })
      .then((response) => {
        dispatch(onLoadResultForHeader(response?.data));
        dispatch(isRequestLoadingForHeader(false));
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        // Throw Error message
        handleThrowError(dispatch, error || "Failed to load files in category")
        dispatch(isRequestLoadingForHeader(false));
      }
      );
  };
}



// export function loadSearchResultsForHeader(searchvalue, quantity, page) {
//   return (dispatch) => {
//     dispatch(isRequestLoadingForHeader(true));
//     return axiosService.Search.load(searchvalue, quantity, page).then(
//       (response) => {
//         //handle success
//         dispatch(onLoadResultForHeader(response?.data));
//         dispatch(isRequestLoadingForHeader(false));
//       },
//       (error) => {
//         dispatch(
//           showErrorMessage({
//             type: "error",
//             message: error,
//             title: "Failed to load search result",
//           })
//         );
//         dispatch(isRequestLoadingForHeader(false));
//       }
//     );
//   };
// }

export function loadSearchResultsForResultsPage(searchvalue, quantity, page) {
  return (dispatch) => {
    dispatch(isRequestLoadingForResults(true));
    return axiosService.Search.loadAllSearchedFiles(searchvalue, quantity, page).then(
      (response) => {
        //handle success
        dispatch(onLoadResultForResults(response?.data));
        dispatch(isRequestLoadingForResults(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load search result",
          })
        );
        dispatch(isRequestLoadingForResults(false));
      }
    );
  };
}
