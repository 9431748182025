import axiosService, { API_ROOT } from "services/axios.service";
import { showErrorMessage, showSuccessMessage } from "./notification";
import { MESSAGE_TYPE } from "store/constant";
import { handleThrowError } from "utils/alerts";
import { binaryToExcelConverter } from "utils/binaryToExcelConverter";
import axios from "axios";

//initial values
const Initial_State = {
  fileCategories: [],
  allFileCategories: {},
  loading: false,
  categoryActionsLoading: false,
  categoryInfoLoading: false,
  error: false,
  folderMetadata: {},
  selectedCategory: {},
  fileCategoriesDetail: [],
  filesInCategory: [],
  categoryMetadata: {},
  categoryGroups: [],
  categoryGroup: [],
  categoryInfo: {},
};

// Action types
const FILE_CATEGORIES = "FILE_CATEGORIES";
const ALL_FILE_CATEGORIES = "ALL_FILE_CATEGORIES"; //Without permission restrictions
const FILE_CATEGORIES_DETAIL = "FILE_CATEGORIES_DETAIL";
const FILES_IN_CATEGORY = "FILES_IN_CATEGORY";
const FILES_IN_CATEGORY_COUNT = "FILES_IN_CATEGORY_COUNT";
const FOLDER_METADATA = "FOLDER_METADATA";
const SELECTED_FILE_CATEGORY = "SELECTED_FILE_CATEGORY";
const CATEGORY_METADATA = "CATEGORY_METADATA";
const LOADING = "LOADING";
const COUNT_LOADING = "COUNT_LOADING";
const CATEGORY_ACTIONS_LOADING = "CATEGORY_ACTIONS_LOADING";
const CATEGORY_INFO_LOADING = "CATEGORY_INFO_LOADING";
const ERROR = "ERROR";
// Category
const CATEGORY_INFO = "CATEGORY_INFO";
// Category groups
const CATEGORY_GROUPS = "CATEGORY_GROUPS";
const CATEGORY_GROUP = "CATEGORY_GROUP";

// Reducer
export default function reducer(state = Initial_State, action = {}) {
  switch (action.type) {
    case FILE_CATEGORIES:
      return {
        ...state,
        error: null,
        loading: false,
        fileCategories: action?.payload?.data,
      };
    case ALL_FILE_CATEGORIES:
      return {
        ...state,
        error: null,
        loading: false,
        allFileCategories: action?.payload?.data,
      };
    case FILE_CATEGORIES_DETAIL:
      return {
        ...state,
        error: null,
        loading: false,
        fileCategoriesDetail: action?.payload?.data,
      };
    case CATEGORY_METADATA:
      return {
        ...state,
        error: null,
        loading: false,
        categoryMetadata: action?.payload?.data?.data,
      };
    case FILES_IN_CATEGORY:
      return {
        ...state,
        error: null,
        loading: false,
        filesInCategory: action?.payload?.data?.data,
      };
    case FILES_IN_CATEGORY_COUNT:
      return {
        ...state,
        error: null,
        loading: false,
        filesInCategoryCount: action?.payload?.data?.data?.total,
      };
    case FOLDER_METADATA:
      return {
        ...state,
        error: null,
        loading: false,
        folderMetadata: action?.payload?.data?.data,
      };
    case SELECTED_FILE_CATEGORY:
      return {
        ...state,
        error: null,
        loading: false,
        selectedCategory: action?.payload,
      };
    case CATEGORY_INFO:
      return {
        ...state,
        error: null,
        loading: false,
        categoryInfo: action?.payload,
      };
    case CATEGORY_GROUPS:
      return {
        ...state,
        error: null,
        loading: false,
        categoryGroups: action?.payload,
      };
    case CATEGORY_GROUP:
      return {
        ...state,
        error: null,
        loading: false,
        categoryGroup: action?.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
        error: null,
      };
    case COUNT_LOADING:
      return {
        ...state,
        countLoading: action.payload,
        error: null,
      };
    case CATEGORY_ACTIONS_LOADING:
      return {
        ...state,
        categoryActionsLoading: action.payload,
        error: null,
      };
    case CATEGORY_INFO_LOADING:
      return {
        ...state,
        categoryInfoLoading: action.payload,
        error: null,
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function isRequestLoading(data) {
  return {
    type: LOADING,
    payload: data,
  };
}

export function isRequestCountLoading(data) {
  return {
    type: COUNT_LOADING,
    payload: data,
  };
}

export function isCategoryActionsLoading(data) {
  return {
    type: CATEGORY_ACTIONS_LOADING,
    payload: data,
  };
}

export function isCategoryInfoLoading(data) {
  return {
    type: CATEGORY_INFO_LOADING,
    payload: data,
  };
}

export function isError(data) {
  return {
    type: ERROR,
    payload: data,
  };
}

export function onFileCategoriesLoad(constant, data) {
  return {
    type: constant,
    payload: data,
  };
}

export function onFileCategoriesDetailLoad(data) {
  return {
    type: FILE_CATEGORIES_DETAIL,
    payload: data,
  };
}

export function onLoadCategoryMetadata(data) {
  return {
    type: FOLDER_METADATA,
    payload: data,
  };
}

export function onLoadFilesInCategory(data) {
  return {
    type: FILES_IN_CATEGORY,
    payload: data,
  };
}

export function onLoadFilesInCategoryCount(data) {
  return {
    type: FILES_IN_CATEGORY_COUNT,
    payload: data,
  };
}

export function onSelectedCategory(data) {
  return {
    type: SELECTED_FILE_CATEGORY,
    payload: data,
  };
}

export function onLoadFolderMetadata(data) {
  return {
    type: CATEGORY_METADATA,
    payload: data,
  };
}

// Category
export function onLoadCategoryInfo(data) {
  return {
    type: CATEGORY_INFO,
    payload: data,
  };
}


// Category groups
export function onLoadCategoryGroups(data) {
  return {
    type: CATEGORY_GROUPS,
    payload: data,
  };
}

export function onLoadACategoryGroup(data) {
  return {
    type: CATEGORY_GROUP,
    payload: data,
  };
}

// Actions
// Load all file categories INCLUDING permission restrictions
export function loadFileCategory() {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileCategory.loadFileCategories().then(
      (response) => {
        dispatch(onFileCategoriesLoad(FILE_CATEGORIES, response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load file categories",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

// Load all file categories EXCLUDING permission restrictions
export function loadAllFileCategories() {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileCategory.loadAllFileCategories().then(
      (response) => {
        dispatch(onFileCategoriesLoad(ALL_FILE_CATEGORIES, response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load file categories",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function loadFileCategoyDetail() {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileCategory.loadFileCategoriesDetails().then(
      (response) => {
        dispatch(onFileCategoriesDetailLoad(response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load file categories",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function loadCategoryMeta(id) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileCategory.loadCategoryMetadata(id).then(
      (response) => {
        dispatch(onLoadCategoryMetadata(response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load category metadata",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}


// Initialize the abort controller
let abortController = null;

export function loadFilesInCategory(id, queryParams) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    // Cancel the previous request
    if (abortController) {
      abortController.abort();
    }

    // Append the query string to the url
    const { page, quantity, search, startDate, endDate, fieldId, fieldName } = queryParams;
    const queryParamURL = new URLSearchParams();
    // Append query parameters only if they exist
    if (page) queryParamURL.append("page", page);
    if (quantity) queryParamURL.append("quantity", quantity);
    if (search) queryParamURL.append("search", search);
    if (fieldId) queryParamURL.append("fieldId", fieldId);
    if (fieldName && (search || (startDate && endDate))) queryParamURL.append("fieldName", fieldName);
    if (startDate && endDate) {
      queryParamURL.append("startDate", startDate);
      queryParamURL.append("endDate", endDate);
    }
    // Construct the final query string
    const structuredQueryParamURL = queryParamURL.toString() ? `?${queryParamURL.toString()}` : "";
    abortController = new AbortController();
    axios
      .get(`${API_ROOT}/api/fileIncategory/${id}${structuredQueryParamURL}`, { signal: abortController.signal })
      .then((response) => {
        dispatch(onLoadFilesInCategory(response));
        dispatch(isRequestLoading(false));
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        // Throw Error message
        handleThrowError(dispatch, error || "Failed to load files in category")
        dispatch(isRequestLoading(false));
      });
  }
}



// export function loadFilesInCategory(id, queryParams) {
//   return (dispatch) => {
//     dispatch(isRequestLoading(true));
//     // Cancel the previous request
//     if (abortController) {
//       abortController.abort();
//     }
//     abortController = new AbortController();

//     const config = {
//       signal: abortController.signal,
//     }
//     return axiosService.FileCategory.loadFilesInCategory(id, queryParams, config).then(
//       (response) => {
//         dispatch(onLoadFilesInCategory(response));
//         dispatch(isRequestLoading(false));
//       },
//       (error) => {
//         dispatch(
//           showErrorMessage({
//             type: "error",
//             message: error,
//             title: "Failed to load file",
//           })
//         );
//         dispatch(isRequestLoading(false));
//       }
//     );
//   };
// }

export function loadFilesInCategoryCount(id, queryParams) {
  return (dispatch) => {
    dispatch(isRequestCountLoading(true));
    return axiosService.FileCategory.loadFilesInCategory(id, queryParams).then(
      (response) => {
        dispatch(onLoadFilesInCategoryCount(response || ""));
        dispatch(isRequestCountLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load file",
          })
        );
        dispatch(isRequestCountLoading(false));
      }
    );
  };
}





export function exportFilesInCategory(id, queryParams) {
  return (dispatch) => {
    dispatch(isCategoryActionsLoading(true));
    return axiosService.FileCategory.exportFilesInCategory(id, queryParams).then(
      (response) => {
        const fileName = `Files in category ${id}`;
        const fileData = response.data
        // Convert binary to excel
        binaryToExcelConverter(fileData, fileName);
        dispatch(isCategoryActionsLoading(false));
      },
      (error) => {
        // Handle Throw Error
        handleThrowError(dispatch, error || "Failed to export files in category",)
        dispatch(isCategoryActionsLoading(false));
      }
    );
  };
};

export function selectedCategory(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    dispatch(onSelectedCategory(data));
    dispatch(isRequestLoading(false));
  };
}

export function loadFolderMetadata(id) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileCategory.loadFolderMetadata(id).then(
      (response) => {
        dispatch(onLoadFolderMetadata(response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load metadata",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function loadFilterFilesInCategory(id, data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileCategory.filterFileCategory(id, data).then(
      (response) => {
        // dispatch(onLoadFilesInCategory(response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load file",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

// Categoru Groups
export function loadCategoryGroups() {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileCategory.loadCategoryGroups().then(
      (response) => {
        dispatch(onLoadCategoryGroups(response.data?.data));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load category groups",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function loadACategoryGroup() {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.FileCategory.loadACategoryGroup().then(
      (response) => {
        dispatch(onLoadACategoryGroup(response.data.data));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load category group",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function createCategoryGroup(data) {
  return (dispatch) => {
    dispatch(isCategoryActionsLoading(true));
    return axiosService.FileCategory.createCategoryGroup(data).then(
      (response) => {
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "Category group saved!",
            title: "Success",
          })
        );
        dispatch(isCategoryActionsLoading(false));
        dispatch(loadCategoryGroups());
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to create category group",
          })
        );
        dispatch(isCategoryActionsLoading(false));
      }
    );
  };
}

export function updateCategoryGroup(data) {
  return (dispatch) => {
    dispatch(isCategoryActionsLoading(true));
    return axiosService.FileCategory.updateCategoryGroup(data).then(
      (response) => {
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "Category group updated successfully!",
            title: "Success",
          })
        );
        dispatch(isCategoryActionsLoading(false));
        dispatch(loadCategoryGroups());
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to update category group",
          })
        );
        dispatch(isCategoryActionsLoading(false));
      }
    );
  };
}

// Categories and sub categories
export function loadCategoryInfo(folderId) {
  return (dispatch) => {
    dispatch(isCategoryInfoLoading(true));
    return axiosService.FileCategory.loadCategoryInfo(folderId).then(
      (response) => {
        dispatch(onLoadCategoryInfo(response.data.data));
        dispatch(isCategoryInfoLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load category info",
          })
        );
        dispatch(isCategoryInfoLoading(false));
      }
    );
  };
}

export function createCategoriesOrSubCategories(data, successMessage) {
  return (dispatch) => {
    dispatch(isCategoryActionsLoading(true));
    return axiosService.FileCategory.createCategoriesOrSubCategories(data).then(
      (response) => {
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: successMessage,
            title: "Success",
          })
        );
        dispatch(isCategoryActionsLoading(false));
        dispatch(loadAllFileCategories());

      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to create category or sub category!",
          })
        );
        dispatch(isCategoryActionsLoading(false));
      }
    );
  };
}

export function updateCategoriesOrSubCategories(data) {
  return (dispatch) => {
    dispatch(isCategoryActionsLoading(true));
    return axiosService.FileCategory.updateCategoriesOrSubCategories(data).then(
      (response) => {
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "Category updated successfully!",
            title: "Success",
          })
        );
        dispatch(isCategoryActionsLoading(false));
        dispatch(loadAllFileCategories());
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to update category!",
          })
        );
        dispatch(isCategoryActionsLoading(false));
      }
    );
  };
}

export function duplicateCategoriesOrSubCategories(data) {
  return (dispatch) => {
    dispatch(isCategoryActionsLoading(true));
    return axiosService.FileCategory.duplicateCategoriesOrSubCategories(data).then(
      (response) => {
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "Category duplicated successfully!",
            title: "Success",
          })
        );
        dispatch(isCategoryActionsLoading(false));
        dispatch(loadAllFileCategories());
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to duplicate category!",
          })
        );
        dispatch(isCategoryActionsLoading(false));
      }
    );
  };
}

export function deleteCategoriesOrSubCategories(data, successMessage, handleCancelCategoryCreation) {
  return (dispatch) => {
    dispatch(isCategoryActionsLoading(true));
    return axiosService.FileCategory.deleteCategoriesOrSubCategories(data).then(
      (response) => {
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: successMessage,
            title: "Success",
          })
        );
        // Clear all states back to default
        handleCancelCategoryCreation && handleCancelCategoryCreation()

        dispatch(onFileCategoriesDetailLoad({}))
        dispatch(loadAllFileCategories());
        dispatch(isCategoryActionsLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to delete category/subcategory!",
          })
        );
        dispatch(isCategoryActionsLoading(false));
      }
    );
  };
}
