export const binaryToAnyFileConverter = (binaryData, fileName) => {
    // Convert binary data to a Blob
    const blob = new Blob([binaryData]);

    // Create a download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the click event on the link
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
}