export const binaryToExcelConverter = (binaryData, title) => {
  // Create a Blob with the correct MIME type (Excel in this case)
  // const blob = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  const blob = new Blob([binaryData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

  // Create a download link
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = title + ".xlsx"; // Name of the file with .xlsx extension

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger the click event on the link
  link.click();

  // Remove the link from the body
  document.body.removeChild(link);
};
